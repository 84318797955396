// colors
$white: #fff;
$black: #564B4B;
$primary-color: #f77d02;

// fonts
$big-font: 1.4em;
$bigger-font: 1.8em;
$biggest-font: 2.2em;
$small-font: 0.8em;
$smaller-font: 0.65em;
$smallest-font: 0.5em;

$gutter: 20px;
$row-spacing: 50px;